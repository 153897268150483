import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import RequirePermission from './components/RequirePermission/RequirePermission';
import UserMetricCollector from './components/UserMetricCollector';
import Layout from './Layout';
import Account from './pages/Account';
import Contacts from './pages/Contacts';
import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import Financials from './pages/Financials';
import MemberResources from './pages/MemberResources';
import SolutionPartners from './pages/SolutionPartners';
import Support from './pages/Support';
import VendorPartners from './pages/VendorPartners';

const App = () : JSX.Element => {
    return (
        <BrowserRouter>
            <UserMetricCollector/>

            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Dashboard/>}/>
                    <Route path="contacts/*" element={<Contacts/>}/>
                    <Route path="financials/*" element={(
                        <RequirePermission permission="read:financials" redirect>
                            <Financials/>
                        </RequirePermission>
                    )}/>
                    <Route path="solution-partners" element={<SolutionPartners/>}/>
                    <Route path="vendor-partners" element={<VendorPartners/>}/>
                    <Route path="member-resources" element={<MemberResources/>}/>
                    <Route path="events" element={<Events/>}/>
                    <Route path="account" element={<Account/>}/>
                    <Route path="support" element={<Support/>}/>
                </Route>

                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
