import {useAuth0} from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import {Autocomplete, Button, Container, Divider, Paper, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useActAs} from '@/components/ActAsProvider';
import Scaffold from '@/components/Scaffold';
import useUser, {CustomProp, hasPermission} from '@/hooks/useUser';
import {useParentCompaniesQuery} from '@/queries/parent-company';

const Account = () : JSX.Element => {
    const user = useUser();
    const {logout} = useAuth0();
    const navigate = useNavigate();
    const [actAsOpen, setActAsOpen] = useState(false);
    const parentCompaniesQuery = useParentCompaniesQuery(actAsOpen);
    const actAs = useActAs();

    return (
        <Scaffold
            appBar={(
                <>
                    <Typography variant="h6" sx={{flexGrow: 1}}>
                        Account
                    </Typography>
                    <Button color="inherit" onClick={() => {
                        navigate('/support');
                    }}>
                        Support
                    </Button>
                </>
            )}
        >
            <Container>
                <Paper sx={{padding: 2, mb: 2}}>
                    <Typography variant="body1" color="grey.600">Email Address</Typography>
                    <Typography variant="body2">{user.email}</Typography>

                    <Divider sx={{my: 2}}/>

                    <Typography variant="body1" color="grey.600">Company</Typography>
                    <Typography variant="body2">{user[CustomProp.company]}</Typography>
                </Paper>

                {hasPermission(user, 'act-as') && (
                    <Paper sx={{padding: 2, mb: 2}}>
                        <Autocomplete
                            renderInput={params => <TextField {...params} label="Act as"/>}
                            getOptionLabel={option => `${option.name} (${option.bhxId})`}
                            loading={parentCompaniesQuery.isLoading}
                            onOpen={() => {
                                setActAsOpen(true);
                            }}
                            onClose={() => {
                                setActAsOpen(false);
                            }}
                            onChange={(event, value) => {
                                actAs.setParentCompany(value);
                            }}
                            options={parentCompaniesQuery.data ?? []}
                            value={actAs.parentCompany}
                            isOptionEqualToValue={(option, value) => option.bhxId === value.bhxId}
                        />
                    </Paper>
                )}

                <Button variant="contained" startIcon={<LogoutIcon/>} fullWidth onClick={() => {
                    logout();
                }}>
                    Sign Out
                </Button>
            </Container>
        </Scaffold>
    );
};

export default Account;
