import {useCallback, useEffect, useRef, useState} from 'react';
import InstallDialog from '@/components/PwaInstall/InstallDialog';
import {getPlatform, Platform} from '@/components/PwaInstall/platforms';

export type BeforeInstallPromptEvent = Event & {
    readonly platforms : string[];
    readonly userChoice : Promise<{
        outcome : 'accepted' | 'dismissed';
        platform : string;
    }>;
    readonly prompt : () => Promise<void>;
};

const rePromptInterval = 3600 * 24 * 7 * 1000;
const platform = getPlatform();
const isInstalled = (
    'standalone' in window.navigator && window.navigator.standalone === true
    || window.matchMedia('(display-mode: standalone)').matches
);

const dismissedAtKey = 'pwaInstallDismissedAt';

const PwaInstall = () : JSX.Element => {
    const deferredPromptEvent = useRef<BeforeInstallPromptEvent>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dismissedAt, setDismissedAt] = useState(() => {
        const dismissedAt = window.localStorage.getItem(dismissedAtKey);

        if (!dismissedAt) {
            return null;
        }

        return parseInt(dismissedAt, 10);
    });

    const shouldPrompt = useCallback(() => {
        return !isInstalled && (dismissedAt === null || dismissedAt + rePromptInterval < Date.now());
    }, [dismissedAt]);

    const handleBeforeInstallPromptEvent = useCallback((event : Event) => {
        event.preventDefault();
        deferredPromptEvent.current = event as BeforeInstallPromptEvent;

        if (shouldPrompt()) {
            setDialogOpen(true);
        }
    }, [shouldPrompt]);

    useEffect(() => {
        if (platform === Platform.native) {
            window.addEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);

            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPromptEvent);
            };
        }

        if (platform === Platform.other) {
            return;
        }

        if (shouldPrompt()) {
            setDialogOpen(true);
        }
    }, [handleBeforeInstallPromptEvent, shouldPrompt]);

    const handleInstall = useCallback(() => {
        setDialogOpen(false);

        const {current} = deferredPromptEvent;

        if (!current) {
            return;
        }

        deferredPromptEvent.current = undefined;

        current.prompt().catch(error => {
            console.error('Failed to prompt for install: ', error);
        });
    }, []);

    const handleDismiss = useCallback(() => {
        const dismissedAt = Date.now();
        setDismissedAt(dismissedAt);
        window.localStorage.setItem(dismissedAtKey, dismissedAt.toString());
        setDialogOpen(false);
    }, []);

    return (
        <InstallDialog
            open={dialogOpen}
            platform={platform}
            onInstall={handleInstall}
            onDismiss={handleDismiss}
        />
    );
};

export default PwaInstall;
