import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    Alert,
    Container,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
} from '@mui/material';
import {useMemo} from 'react';
import BackHeader from '@/components/BackHeader';
import Scaffold from '@/components/Scaffold';
import useOidcFetch from '@/hooks/useOidcFetch';
import {useFinancialsStatementsQuery} from '@/queries/financials';
import type {FinancialsStatement} from '@/types/financials';
import {apiUrl} from '@/utils/api';

const Statements = () : JSX.Element => {
    const statementsQuery = useFinancialsStatementsQuery();
    const fetch = useOidcFetch();

    const bhGroups = useMemo(() => {
        if (!statementsQuery.data) {
            return null;
        }

        const groups = new Map<number, FinancialsStatement[]>();

        for (const statement of statementsQuery.data) {
            let statements = groups.get(statement.bhId);

            if (!statements) {
                statements = [];
                groups.set(statement.bhId, statements);
            }

            statements.push(statement);
        }

        return [...groups.entries()].map(([bhId, statements]) => ({bhId, statements}));
    }, [statementsQuery.data]);

    const handleClick = async (statement : FinancialsStatement) : Promise<void> => {
        const response = await fetch(
            apiUrl(
                `/financials/statements/${statement.id}/download-url`,
            ).toString()
        );

        if (!response.ok) {
            return;
        }

        const result = await response.json() as {
            data : {
                url : string;
            };
        };

        window.open(result.data.url, '_blank', 'noreferrer');
    };

    return (
        <Scaffold
            appBar={(
                <BackHeader previousPath="/financials">Member Rebate Statements</BackHeader>
            )}
        >
            <Container>
                {statementsQuery.isLoading && <LinearProgress/>}

                {statementsQuery.isError && (
                    <Alert severity="error">
                        Unable to load statements.
                    </Alert>
                )}

                {bhGroups && (
                    <Paper>
                        {bhGroups.map(bhGroup => (
                            <List
                                key={bhGroup.bhId}
                                subheader={<ListSubheader>BH {bhGroup.bhId}</ListSubheader>}
                            >
                                {bhGroup.statements.map(statement => (
                                    <ListItem
                                        key={`${statement.bhId}-${statement.year}-${statement.quarter}`}
                                        disablePadding
                                    >
                                        <ListItemButton
                                            onClick={() => {
                                                void handleClick(statement);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <PictureAsPdfIcon/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                {statement.year}{' '}
                                                {statement.period === 'quarter'
                                                    ? `Q${statement.quarter}`
                                                    : `M${statement.month}`
                                                }
                                            </ListItemText>
                                            <DownloadIcon/>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        ))}
                    </Paper>
                )}
            </Container>
        </Scaffold>
    );
};

export default Statements;
