import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {Container, Typography, Unstable_Grid2 as Grid} from '@mui/material';
import GridButton from '@/components/GridButton';
import Scaffold from '@/components/Scaffold';

const Overview = () : JSX.Element => {
    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Financial Highlights
                </Typography>
            )}
        >
            <Container>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <GridButton
                            path="/financials/totals/rebate"
                            label="Member Total Rebates"
                            Icon={CurrencyExchangeIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/financials/totals/purchase"
                            label="Member Total Purchases"
                            Icon={ShoppingCartIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/financials/statements"
                            label="Member Rebate Statements"
                            Icon={SummarizeIcon}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Scaffold>
    );
};

export default Overview;
