import PlaceIcon from '@mui/icons-material/Place';
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography} from '@mui/material';

type Props = {
    imageUrl : string;
    title : string;
    date : string;
    url ?: string;
    location ?: {
        name : string;
        address : string;
    };
};

const EventCard = ({imageUrl, title, date, location, url} : Props) : JSX.Element => {
    return (
        <Card>
            <CardActionArea onClick={() => {
                window.open(url ?? 'https://www.bluehawk.coop/events/');
            }}>
                <CardMedia
                    component="img"
                    image={imageUrl}
                    alt={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="body2" component="div">
                        {date}
                    </Typography>

                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>

                    {location && (
                        <>
                            <Typography gutterBottom>{location.name}</Typography>
                            <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', color: 'grey.600'}}>
                                <PlaceIcon fontSize="small"/>
                                <Typography variant="body2" sx={{lineHeight: 1}}>{location.address}</Typography>
                            </Box>
                        </>
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default EventCard;
