import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import {useActAs} from '@/components/ActAsProvider';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToFinancialsStatement, mapRawToFinancialsVendor} from '@/mappers/financials';
import type {
    FinancialsStatement,
    FinancialsVendor,
    RawFinancialsStatement,
    RawFinancialsVendor,
} from '@/types/financials';
import type {JsonApiDocument} from '@/types/json-api';
import {apiUrl} from '@/utils/api';

export const useFinancialsVendorsQuery = () : UseQueryResult<FinancialsVendor[]> => {
    const fetch = useOidcFetch();
    const actAs = useActAs();

    return useQuery(['financials', 'vendors', actAs.parentCompany?.bhxId], async ({signal}) => {
        const response = await fetch(apiUrl('/financials/vendors').toString(), {
            signal,
            headers: actAs.parentCompany
                ? {
                    'X-BHX-ID': actAs.parentCompany.bhxId.toString(),
                }
                : undefined,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch financials vendors');
        }

        const raw = await response.json() as JsonApiDocument<RawFinancialsVendor[]>;
        return raw.data.map(mapRawToFinancialsVendor);
    });
};

export const useFinancialsStatementsQuery = () : UseQueryResult<FinancialsStatement[]> => {
    const fetch = useOidcFetch();

    return useQuery(['financials', 'statements'], async ({signal}) => {
        const response = await fetch(apiUrl('/financials/statements').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch financials statements');
        }

        const raw = await response.json() as JsonApiDocument<RawFinancialsStatement[]>;
        return raw.data.map(mapRawToFinancialsStatement);
    });
};
