import {
    Alert,
    Autocomplete,
    Box,
    Card,
    CardContent,
    Container,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {useMemo, useState} from 'react';
import Scaffold from '@/components/Scaffold';
import {useVendorPartnersQuery} from '@/queries/vendor-partner';

const collator = new Intl.Collator('en-US');

const VendorPartners = () : JSX.Element => {
    const partnersQuery = useVendorPartnersQuery();
    const [filterByCategory, setFilterByCategory] = useState<string | null>(null);

    const categoryOptions = useMemo(() => {
        if (!partnersQuery.data) {
            return [];
        }

        return [...new Set(
            partnersQuery.data.flatMap(partner => partner.categories)
        )].sort((a, b) => collator.compare(a, b));
    }, [partnersQuery.data]);

    const sortedPartners = useMemo(() => {
        if (!partnersQuery.data) {
            return null;
        }

        return [...partnersQuery.data].sort((a, b) => collator.compare(a.name, b.name));
    }, [partnersQuery.data]);

    const filteredPartners = useMemo(() => {
        if (!sortedPartners || !filterByCategory) {
            return sortedPartners;
        }

        return sortedPartners.filter(partner => partner.categories.includes(filterByCategory));
    }, [sortedPartners, filterByCategory]);

    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Product Categories
                </Typography>
            )}
        >
            <Container>
                <Autocomplete
                    renderInput={params => <TextField {...params} label="Search Vendors by Category…"/>}
                    loading={!partnersQuery.data}
                    disabled={partnersQuery.data === undefined}
                    options={categoryOptions}
                    onChange={(event, option) => {
                        setFilterByCategory(option);
                    }}
                />

                {partnersQuery.isLoading && <LinearProgress/>}

                <Box sx={{mt: 2}}>
                    {partnersQuery.isError && (
                        <Alert severity="error">
                            Unable to load partners.
                        </Alert>
                    )}

                    {filteredPartners && (
                        <Stack spacing={2}>
                            {filteredPartners.map(partner => (
                                <Card key={partner.id}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {partner.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {partner.summary}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Stack>
                    )}
                </Box>
            </Container>
        </Scaffold>
    );
};

export default VendorPartners;
