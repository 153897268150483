import {useAuth0} from '@auth0/auth0-react';
import type {ReactNode} from 'react';
import {useEffect} from 'react';
import ErrorFallback from './ErrorFallback';
import Spinner from './Spinner';

type Props = {
    children : ReactNode;
};

const OidcSecure = ({children} : Props) : JSX.Element => {
    const {isAuthenticated, isLoading, loginWithRedirect, error} = useAuth0();

    useEffect(() => {
        if (isLoading || isAuthenticated || error) {
            return;
        }

        void loginWithRedirect();
    }, [isLoading, isAuthenticated, error, loginWithRedirect]);

    if (error) {
        return <ErrorFallback/>;
    }

    if (isAuthenticated) {
        return <>{children}</>;
    }

    return <Spinner/>;
};

export default OidcSecure;
