import {Navigate, Route, Routes} from 'react-router-dom';
import Overview from './Overview';
import Statements from './Statements';
import Totals from './Totals';

const Financials = () : JSX.Element => {
    return (
        <Routes>
            <Route index element={<Overview/>}/>
            <Route path="totals/:type" element={<Totals/>}/>
            <Route path="statements" element={<Statements/>}/>

            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Financials;
