import {Divider} from '@mui/material';
import type {ReactNode} from 'react';
import {Children, Fragment} from 'react';

type Props = {
    children : ReactNode;
};

const DividedList = ({children} : Props) : JSX.Element => {
    return (
        <>
            {Children.toArray(children).filter(Boolean).map((child, index) => (
                <Fragment key={index}>
                    {index > 0 && <Divider sx={{my: 1}}/>}
                    {child}
                </Fragment>
            ))}
        </>
    );
};

export default DividedList;
