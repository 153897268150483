import {SvgIcon} from '@mui/material';

const OperaA2hsIcon = () : JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="
                    M5 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4zm2 .51A.51.51 0 0 1 7.51
                    4h8.98a.51.51 0 0 1 .51.51v13.98a.51.51 0 0 1-.51.51H7.51a.51.51 0 0 1-.51-.51V4.51zM10.5 20a.5.5 0
                    1 0 0 1h3a.5.5 0 1 0 0-1h-3z
                "
                fill="context-fill"
                fillOpacity=".8"
            ></path>
        </SvgIcon>
    );
};

export default OperaA2hsIcon;
