import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton, Typography} from '@mui/material';
import type {ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

type Props = {
    previousPath : string;
    children : ReactNode;
};

const BackHeader = ({previousPath, children} : Props) : JSX.Element => {
    const navigate = useNavigate();

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{mr: 2}}
                onClick={() => {
                    navigate(previousPath);
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Typography variant="h6">
                {children}
            </Typography>
        </>
    );
};

export default BackHeader;
