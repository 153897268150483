import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import {Container, Divider, List, Paper, Typography} from '@mui/material';
import {parsePhoneNumber} from 'libphonenumber-js/min';
import Scaffold from '@/components/Scaffold';
import ContactListItem from '@/pages/Support/ContactListItem';
import SupportRequestForm from '@/pages/Support/SupportRequestForm';

const phoneNumber = parsePhoneNumber('480-656-7830', 'US');
const emailAddress = 'info@bluehawkcooperative.com';

const Support = () : JSX.Element => {
    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Help
                </Typography>
            )}
        >
            <Container>
                <Paper>
                    <List>
                        <ContactListItem
                            label={phoneNumber.formatNational()}
                            href={phoneNumber.format('RFC3966')}
                            icon={<PhoneIcon/>}
                        />
                        <Divider sx={{my: 1}}/>
                        <ContactListItem
                            label={emailAddress}
                            href={`mailto:${emailAddress}`}
                            icon={<EmailIcon/>}
                        />
                    </List>
                </Paper>

                <Typography variant="body2" sx={{my: 2}}>
                    Use the text area below to describe your issue, then click &quot;Submit Request&quot;.
                </Typography>

                <SupportRequestForm/>
            </Container>
        </Scaffold>
    );
};

export default Support;
