import type {ReactNode} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import {useCreateErrorMutation} from '@/mutations/error';

type Props = {
    children : ReactNode;
};

const ErrorHandler = ({children} : Props) : JSX.Element => {
    const createErrorMutation = useCreateErrorMutation();

    const handleError = (error : Error, info : {componentStack : string}) => {
        createErrorMutation.mutate({
            error,
            componentStack: info.componentStack,
        });
    };

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={handleError}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorHandler;
