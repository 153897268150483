import {
    Alert,
    Autocomplete,
    Box,
    Card,
    CardContent,
    Container,
    LinearProgress,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import {useMemo, useState} from 'react';
import Scaffold from '@/components/Scaffold';
import {useSolutionPartnersQuery} from '@/queries/solution-partner';

const collator = new Intl.Collator('en-US');

const SolutionPartners = () : JSX.Element => {
    const partnersQuery = useSolutionPartnersQuery();
    const [filterByService, setFilterByService] = useState<string | null>(null);

    const serviceOptions = useMemo(() => {
        if (!partnersQuery.data) {
            return [];
        }

        return [...new Set(
            partnersQuery.data.flatMap(partner => partner.services)
        )].sort((a, b) => collator.compare(a, b));
    }, [partnersQuery.data]);

    const sortedPartners = useMemo(() => {
        if (!partnersQuery.data) {
            return null;
        }

        return [...partnersQuery.data].sort((a, b) => collator.compare(a.name, b.name));
    }, [partnersQuery.data]);

    const filteredPartners = useMemo(() => {
        if (!sortedPartners || !filterByService) {
            return sortedPartners;
        }

        return sortedPartners.filter(partner => partner.services.includes(filterByService));
    }, [sortedPartners, filterByService]);

    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Value Added Business Services
                </Typography>
            )}
        >
            <Container>
                <Autocomplete
                    renderInput={params => <TextField {...params} label="Search Partners by Service…"/>}
                    loading={!partnersQuery.data}
                    disabled={partnersQuery.data === undefined}
                    options={serviceOptions}
                    onChange={(event, option) => {
                        setFilterByService(option);
                    }}
                />

                {partnersQuery.isLoading && <LinearProgress/>}

                <Box sx={{mt: 2}}>
                    {partnersQuery.isError && (
                        <Alert severity="error">
                            Unable to load partners.
                        </Alert>
                    )}

                    {filteredPartners && (
                        <Stack spacing={2}>
                            {filteredPartners.map(partner => (
                                <Card key={partner.id}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {partner.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {partner.summary}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            ))}
                        </Stack>
                    )}
                </Box>
            </Container>
        </Scaffold>
    );
};

export default SolutionPartners;
