import BlueHawkStaffCard from './Cards/BlueHawkStaffCard';
import ContactList from '@/pages/Contacts/ContactList';
import {useSearch} from '@/pages/Contacts/helpers/search';
import {useContactsByTypeQuery} from '@/queries/contact';
import type {BhsContact} from '@/types/contact';

const collator = new Intl.Collator('en-US', {sensitivity: 'base'});

const sort = (a : BhsContact, b : BhsContact) : number => {
    return collator.compare(a.name, b.name);
};

const searchKeys = ['name', 'title'];

const BlueHawkStaff = () : JSX.Element => {
    const contactsQuery = useContactsByTypeQuery('bhs');
    const search = useSearch(contactsQuery, sort, searchKeys);

    return (
        <ContactList
            title="BLUE HAWK Staff"
            contactsQuery={contactsQuery}
            search={search}
            renderCard={contact => (
                <BlueHawkStaffCard contact={contact}/>
            )}
        />
    );
};

export default BlueHawkStaff;
