import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HandshakeIcon from '@mui/icons-material/Handshake';
import {Box, Card, CardActionArea, CardContent, CardHeader} from '@mui/material';
import {useState} from 'react';
import ContactDialog from './ContactDialog';
import type {MpBodP2pContact} from '@/types/contact';

type Props = {
    contact : MpBodP2pContact;
};

const MemberCard = ({contact} : Props) : JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const subheader = [contact.name];

    if (contact.title) {
        subheader.push(contact.title);
    }

    return (
        <Card>
            <CardActionArea onClick={() => {
                setDialogOpen(true);
            }}>
                <CardHeader
                    title={contact.p2pGroup
                        ? (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {contact.companyName}
                                <HandshakeIcon sx={{ml: 1}}/>
                            </Box>
                        )
                        : contact.companyName
                    }
                    subheader={subheader.join(' - ')}
                    action={<ArrowForwardIcon sx={{mt: 1, mr: 1, ml: 1}}/>}
                />
                {contact.doingBusinessAs.length > 0 && (
                    <CardContent>
                        {contact.doingBusinessAs.map(dba => dba.name).join(', ')}
                    </CardContent>
                )}
            </CardActionArea>

            <ContactDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                contact={contact}
            />
        </Card>
    );
};

export default MemberCard;
