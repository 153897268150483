import {Alert, Autocomplete, Box, Container, LinearProgress, TextField} from '@mui/material';
import {useMemo, useState} from 'react';
import ChartTabs from './ChartTabs';
import BackHeader from '@/components/BackHeader';
import Scaffold from '@/components/Scaffold';
import {useFinancialsVendorsQuery} from '@/queries/financials';
import type {Company, FinancialsVendor, YearValues} from '@/types/financials';

const collator = new Intl.Collator('en-US');

const Totals = () : JSX.Element => {
    const financialsVendorsQuery = useFinancialsVendorsQuery();
    const [vendor, setVendor] = useState<FinancialsVendor | null>(null);
    const [company, setCompany] = useState<Company | null>(null);

    const companies = useMemo(() => {
        if (!financialsVendorsQuery.data) {
            return [];
        }

        const companies = financialsVendorsQuery.data.reduce((companies, vendor) => {
            if (!companies.has(vendor.company.bhxId)) {
                companies.set(vendor.company.bhxId, vendor.company);
            }

            return companies;
        }, new Map<number, Company>());

        return [...companies.values()].sort((a, b) => collator.compare(a.name, b.name));
    }, [financialsVendorsQuery.data]);

    const selectedCompany = company ?? (companies.length === 1 ? companies[0] : null);

    const vendors = useMemo(() => {
        if (!financialsVendorsQuery.data || !selectedCompany) {
            return [];
        }

        return financialsVendorsQuery.data
            .filter(vendor => vendor.company.bhxId === selectedCompany.bhxId)
            .sort((a, b) => collator.compare(a.name, b.name));
    }, [financialsVendorsQuery.data, selectedCompany]);

    const summary = useMemo(() => {
        if (!financialsVendorsQuery.data) {
            return null;
        }

        const yearValues = new Map<number, YearValues>();

        for (const vendor of financialsVendorsQuery.data) {
            if (company && company.bhxId !== vendor.company.bhxId) {
                continue;
            }

            for (const {year, rebate, purchase} of vendor.years) {
                let yearValue = yearValues.get(year);

                if (!yearValue) {
                    yearValue = {year, rebate: 0, purchase: 0};
                    yearValues.set(year, yearValue);
                }

                yearValue.rebate += rebate;
                yearValue.purchase += purchase;
            }
        }

        return [...yearValues.values()];
    }, [financialsVendorsQuery.data, company]);

    return (
        <Scaffold
            motionKey="/financials/totals"
            appBar={(
                <BackHeader previousPath="/financials">Financial Totals</BackHeader>
            )}
        >
            <Container>
                <Autocomplete
                    renderInput={params => <TextField {...params} label="Company"/>}
                    loading={!financialsVendorsQuery.data}
                    disabled={financialsVendorsQuery.data === undefined || companies.length === 1}
                    options={companies}
                    isOptionEqualToValue={(option, value) => option.bhxId === value.bhxId}
                    getOptionLabel={option => option.name}
                    value={selectedCompany}
                    onChange={(event, option) => {
                        setVendor(null);
                        setCompany(option);
                    }}
                    sx={{mb: 2}}
                />

                <Autocomplete
                    renderInput={params => <TextField {...params} label="Vendor"/>}
                    loading={!financialsVendorsQuery.data}
                    disabled={financialsVendorsQuery.data === undefined || !selectedCompany}
                    options={vendors}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={option => option.name}
                    value={vendor}
                    onChange={(event, option) => {
                        setVendor(option);
                    }}
                />

                {financialsVendorsQuery.isLoading && <LinearProgress/>}

                <Box sx={{mt: 2}}>
                    {financialsVendorsQuery.isError && (
                        <Alert severity="error">
                            Unable to load financials.
                        </Alert>
                    )}

                    {summary && <ChartTabs summary={summary} vendor={vendor}/>}
                </Box>
            </Container>
        </Scaffold>
    );
};

export default Totals;
