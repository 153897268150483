import {Paper, useTheme} from '@mui/material';
import {BarElement, CategoryScale, Chart as ChartJS, LinearScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useMemo} from 'react';
import {Bar} from 'react-chartjs-2';
import type {YearValues} from '@/types/financials';

export type ChartType = 'rebate' | 'purchase';

type Props = {
    years : YearValues[];
    type : ChartType;
};

ChartJS.register({
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const Chart = ({years, type} : Props) : JSX.Element => {
    const theme = useTheme();
    const currentYear = new Date().getFullYear();
    const processedYears = useMemo(() => {
        const processedYears = years.map(year => ({
            ...year,
            year: year.year === currentYear ? `YTD ${currentYear}` : year.year,
        }));

        if (!processedYears.some(year => year.year === `YTD ${currentYear}`)) {
            processedYears.push({
                year: `YTD ${currentYear}`,
                rebate: 0,
                purchase: 0,
            });
        }

        return processedYears
            .sort((a, b) => {
                if (typeof a.year === 'string') {
                    return 1;
                }

                if (typeof b.year === 'string') {
                    return -1;
                }

                return a.year - b.year;
            });
    }, [years, currentYear]);

    const values = processedYears.map(year => year[type]);

    return (
        <Paper>
            <Bar
                height={300}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 30,
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: 0,
                            formatter: value => currencyFormatter.format(value as number),
                        },
                    },
                    scales: {
                        y: {
                            display: false,
                            min: Math.min(0, ...values),
                            max: Math.max(0, ...values),
                        },
                        x: {
                            grid: {
                                display: false,
                            },
                        },
                    },
                }}
                data={{
                    labels: processedYears.map(year => year.year),
                    datasets: [{
                        data: values,
                        backgroundColor: theme.palette.primary.main,
                    }],
                }}
            />
        </Paper>
    );
};

export default Chart;
