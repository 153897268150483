import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToContact} from '@/mappers/contact';
import type {
    ContactOfType,
    ContactType,
    ContactWithP2pGroup,
    RawContactOfType,
    RawMpBodP2pContact,
} from '@/types/contact';
import type {JsonApiDocument} from '@/types/json-api';
import {apiUrl} from '@/utils/api';

export const useContactsByTypeQuery = <T extends ContactType>(type : T) : UseQueryResult<Array<ContactOfType<T>>> => {
    const fetch = useOidcFetch();

    return useQuery(['contacts', type], async ({signal}) => {
        const response = await fetch(apiUrl(`/contacts/${type}`).toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch contacts');
        }

        const raw = await response.json() as JsonApiDocument<Array<RawContactOfType<T>>>;
        return raw.data.map(mapRawToContact);
    });
};

export const useP2pContactsQuery = () : UseQueryResult<ContactWithP2pGroup[]> => {
    const fetch = useOidcFetch();

    return useQuery(['contacts', 'onlyP2p'], async ({signal}) => {
        const response = await fetch(apiUrl('/contacts?onlyP2p=true').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch contacts');
        }

        const raw = await response.json() as JsonApiDocument<RawMpBodP2pContact[]>;
        return raw.data.map(mapRawToContact);
    });
};
