import {DateTimeFormatter} from '@js-joda/core';
import CloseIcon from '@mui/icons-material/Close';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    AppBar, Box,
    Card,
    CardContent,
    Container,
    Dialog,
    IconButton, Link,
    Slide,
    Stack,
    styled,
    Toolbar,
    Typography,
} from '@mui/material';
import type {TransitionProps} from '@mui/material/transitions';
import type {ReactElement, Ref} from 'react';
import {forwardRef} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import DividedList from '@/pages/Contacts/Cards/DividedList';
import ListItem, {EmailListItem, PhoneListItem} from '@/pages/Contacts/Cards/ListItem';
import AddressListItem from '@/pages/Contacts/Cards/ListItem/AddressListItem';
import type {MpBodP2pContact, VpSapContact} from '@/types/contact';

const Transition = forwardRef((
    props : TransitionProps & {
        children : ReactElement;
    },
    ref : Ref<unknown>,
) => {
    return <Slide direction="left" ref={ref} {...props}/>;
});
Transition.displayName = 'Transition';

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    '@media all': {
        minHeight: 128,
    },
}));

type Props = {
    open : boolean;
    onClose : () => void;
    contact : MpBodP2pContact | VpSapContact;
};

const dateFormatter = DateTimeFormatter.ofPattern('M/d/yyyy');

const ContactDialog = ({open, onClose, contact} : Props) : JSX.Element => {
    const isMpBod = contact.type === 'mp' || contact.type === 'bod';
    const isVpSap = contact.type === 'vp' || contact.type === 'sap';

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.default',
                },
            }}
        >
            <AppBar sx={{position: 'relative'}}>
                <StyledToolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Box sx={{ml: 2, flexGrow: 1, alignSelf: 'flex-end'}}>
                        <Typography variant="h6" component="div">
                            {contact.name}
                        </Typography>
                        {contact.title && (
                            <Typography variant="body2" color="grey.400">
                                {contact.title}
                            </Typography>
                        )}
                    </Box>
                    {contact.phoneNumber && (
                        <IconButton
                            size="large"
                            aria-label="call"
                            color="inherit"
                            edge="end"
                            component="a"
                            href={contact.phoneNumber.format('RFC3966')}
                        >
                            <PhoneIcon/>
                        </IconButton>
                    )}
                </StyledToolbar>
            </AppBar>

            <Container sx={{my: 2}}>
                <Card>
                    <CardContent>
                        <DividedList>
                            <ListItem label="Company" multiline>
                                {contact.companyName}
                            </ListItem>

                            {isMpBod && contact.p2pGroup && (
                                <ListItem label="P2P Group">
                                    <Link
                                        component={RouterLink}
                                        to={{
                                            pathname: '/contacts/p2p-networking-groups',
                                            search: `query=${encodeURIComponent(contact.p2pGroup)}`,
                                        }}
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <HandshakeIcon sx={{mr: 0.5}} fontSize="small"/>
                                            {contact.p2pGroup}
                                        </Box>
                                    </Link>
                                </ListItem>
                            )}

                            {isMpBod && contact.branchCount && (
                                <ListItem label="Branches">
                                    {contact.branchCount.toString()}
                                </ListItem>
                            )}

                            {isMpBod && contact.doingBusinessAs.length > 0 && (
                                <ListItem label="Doing Business As" multiline>
                                    {contact.doingBusinessAs.map(dba => dba.name).join(', ')}
                                </ListItem>
                            )}

                            {contact.joinedOn && (
                                <ListItem label="Joined Date">
                                    {contact.joinedOn.format(dateFormatter)}
                                </ListItem>
                            )}

                            {(contact.phoneNumber || contact.faxNumber) && (
                                <Stack direction="row">
                                    {contact.phoneNumber && (
                                        <Box sx={{flexGrow: 1}}>
                                            <PhoneListItem label="Phone">
                                                {contact.phoneNumber}
                                            </PhoneListItem>
                                        </Box>

                                    )}
                                    {contact.faxNumber && (
                                        <Box sx={{flexGrow: 1}}>
                                            <PhoneListItem label="Fax">
                                                {contact.faxNumber}
                                            </PhoneListItem>
                                        </Box>

                                    )}
                                </Stack>
                            )}

                            {contact.emailAddress && (
                                <EmailListItem label="Email">{contact.emailAddress}</EmailListItem>
                            )}

                            {contact.address && (
                                <AddressListItem label="Address">{contact.address}</AddressListItem>
                            )}

                            {isVpSap && contact.productCategory && (
                                <ListItem label="Product Category">
                                    {contact.productCategory}
                                </ListItem>
                            )}

                            {isVpSap && contact.descriptionOfService && (
                                <ListItem label="Description of Service" multiline>
                                    {contact.descriptionOfService}
                                </ListItem>
                            )}
                        </DividedList>
                    </CardContent>
                </Card>
            </Container>
        </Dialog>
    );
};

export default ContactDialog;
