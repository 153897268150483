import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContactsIcon from '@mui/icons-material/Contacts';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HomeIcon from '@mui/icons-material/Home';
import {BottomNavigation, BottomNavigationAction, bottomNavigationActionClasses, Paper, styled} from '@mui/material';
import {useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import useUser, {hasPermission} from '@/hooks/useUser';

enum NavigationKey {
    dashboard = '/',
    contacts = '/contacts',
    financials = '/financials',
    account = '/account',
}

const NavigationRoot = styled(Paper)(({theme}) => ({
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    pl: 'env(safe-area-inset-left)',
    pb: 'env(safe-area-inset-bottom)',
    pr: 'env(safe-area-inset-right)',
    backgroundColor: theme.palette.primary.main,
}));

const StyledBottomNavigation = styled(BottomNavigation)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
}));

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({theme}) => ({
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
    [`&.${bottomNavigationActionClasses.selected}`]: {
        color: theme.palette.primary.contrastText,
        opacity: 1,
    },
}));

const Navigation = () : JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useUser();

    const currentNavigationKey = useMemo(() => {
        if (location.pathname === '/') {
            return NavigationKey.dashboard;
        }

        for (const path of Object.values(NavigationKey)) {
            if (path === '/') {
                continue;
            }

            if (location.pathname.startsWith(path)) {
                return path;
            }
        }

        return null;
    }, [location]);

    return (
        <NavigationRoot>
            <StyledBottomNavigation
                showLabels
                value={currentNavigationKey}
                onChange={(event, value : NavigationKey) => {
                    navigate(value);
                }}
            >
                <StyledBottomNavigationAction
                    label="Home"
                    icon={<HomeIcon/>}
                    value={NavigationKey.dashboard}
                />
                <StyledBottomNavigationAction
                    label="Contacts"
                    icon={<ContactsIcon/>}
                    value={NavigationKey.contacts}
                />
                {hasPermission(user, 'read:financials') && (
                    <StyledBottomNavigationAction
                        label="Financials"
                        icon={<AttachMoneyIcon/>}
                        value={NavigationKey.financials}
                    />
                )}
                <StyledBottomNavigationAction
                    label="Account"
                    icon={<FingerprintIcon/>}
                    value={NavigationKey.account}
                />
            </StyledBottomNavigation>
        </NavigationRoot>
    );
};

export default Navigation;
