import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Box, Fab, Fade, useScrollTrigger} from '@mui/material';
import {useRef} from 'react';

const ScrollToTop = () : JSX.Element => {
    const topRef = useRef<HTMLDivElement>();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <>
            <Box ref={topRef} sx={{position: 'absolute', left: 0, top: 0}}/>
            <Fade in={trigger}>
                <Box
                    onClick={handleClick}
                    role="presentation"
                    sx={{position: 'fixed', bottom: 72, right: 16}}
                >
                    <Fab size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon/>
                    </Fab>
                </Box>
            </Fade>
        </>
    );
};

export default ScrollToTop;
