import {Slide, useScrollTrigger} from '@mui/material';
import type {ReactElement} from 'react';

type Props = {
    children : ReactElement;
};

const HideOnScroll = ({children} : Props) : JSX.Element => {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

export default HideOnScroll;
