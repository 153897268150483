import type {UseQueryResult} from '@tanstack/react-query';
import Fuse from 'fuse.js';
import type {Dispatch, SetStateAction} from 'react';
import {useMemo, useState} from 'react';
import type {Contact, MpBodP2pContact, VpSapContact} from '@/types/contact';

export type UseSearchResult<T extends Contact> = {
    contacts : T[] | null;
    query : string;
    setQuery : Dispatch<SetStateAction<string>>;
};

export const useSearch = <T extends Contact>(
    contactsQuery : UseQueryResult<T[]>,
    sort : (a : T, b : T) => number,
    searchKeys : Array<Fuse.FuseOptionKey<T>>,
) : UseSearchResult<T> => {
    const [query, setQuery] = useState('');

    const sortedContacts = useMemo(() => {
        if (!contactsQuery.data) {
            return null;
        }

        return [...contactsQuery.data].sort(sort);
    }, [contactsQuery.data, sort]);

    const fuse = useMemo(() => {
        if (!sortedContacts) {
            return null;
        }

        return new Fuse(sortedContacts, {
            keys: searchKeys,
            ignoreLocation: true,
            threshold: 0.0,
        });
    }, [sortedContacts, searchKeys]);

    const contacts = useMemo(() => {
        if (!fuse) {
            return null;
        }

        if (query === '' && sortedContacts) {
            return sortedContacts;
        }

        return fuse.search(query).map(result => result.item);
    }, [fuse, query, sortedContacts]);

    return {
        contacts,
        query,
        setQuery,
    };
};

const collator = new Intl.Collator('en-US', {sensitivity: 'base'});

export const partnerSort = (a : VpSapContact, b : VpSapContact) : number => {
    return collator.compare(a.companyName, b.companyName);
};

export const partnerSearchKeys : Array<Fuse.FuseOptionKey<VpSapContact>> = ['companyName', 'descriptionOfService'];

export const memberSort = (a : MpBodP2pContact, b : MpBodP2pContact) : number => {
    return collator.compare(a.companyName, b.companyName);
};

export const memberSearchKeys : Array<Fuse.FuseOptionKey<MpBodP2pContact>> = [
    'companyName',
    'name',
    'title',
    'doingBusinessAs.name',
];
