import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import logoSquare from '@/assets/logo-square.svg';

const Spinner = () : JSX.Element => {
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Stack spacing={2} alignItems="center" sx={{position: 'relative'}}>
                <Box sx={{position: 'relative', height: 80}}>
                    <Box component="img" src={logoSquare} height={40} sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginLeft: '-20px',
                        marginTop: '-20px',
                    }}/>
                    <CircularProgress size={80}/>
                </Box>
                <Typography>Signing you in…</Typography>
            </Stack>
        </Box>
    );
};

export default Spinner;
