import MemberCard from '@/pages/Contacts/Cards/MemberCard';
import ContactList from '@/pages/Contacts/ContactList';
import {memberSearchKeys, memberSort, useSearch} from '@/pages/Contacts/helpers/search';
import {useContactsByTypeQuery} from '@/queries/contact';

const MemberPrimaryContacts = () : JSX.Element => {
    const contactsQuery = useContactsByTypeQuery('mp');
    const search = useSearch(contactsQuery, memberSort, memberSearchKeys);

    return (
        <ContactList
            title="Member Primary Contacts"
            contactsQuery={contactsQuery}
            search={search}
            renderCard={contact => (
                <MemberCard contact={contact}/>
            )}
        />
    );
};

export default MemberPrimaryContacts;
