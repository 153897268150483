import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {useSnackbar} from 'notistack';
import type {ReactNode} from 'react';

type Props = {
    label : string;
    href : string;
    icon : ReactNode;
};

const ContactListItem = ({label, href, icon} : Props) : JSX.Element => {
    const {enqueueSnackbar} = useSnackbar();

    const copyToClipboard = (value : string) => {
        navigator.clipboard.writeText(value)
            .then(() => {
                enqueueSnackbar('Copied to clipboard', {variant: 'success'});
            })
            .catch(() => {
                enqueueSnackbar('Failed to copy to clipboard', {variant: 'error'});
            });
    };

    return (
        <ListItem
            disablePadding
            secondaryAction={(
                <IconButton edge="end" onClick={() => {
                    copyToClipboard(label);
                }}>
                    <ContentCopyIcon/>
                </IconButton>
            )}
        >
            <ListItemButton component="a" target="_blank" href={href}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                >
                    {label}
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export default ContactListItem;
