import type {
    FinancialsStatement,
    FinancialsVendor,
    RawFinancialsStatement,
    RawFinancialsVendor,
} from '@/types/financials';

export const mapRawToFinancialsVendor = (raw : RawFinancialsVendor) : FinancialsVendor => raw;

export const mapRawToFinancialsStatement = (raw : RawFinancialsStatement) : FinancialsStatement => raw;
