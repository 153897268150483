import {Container, Stack, Typography} from '@mui/material';
import AC2025 from '@/assets/events/AC2025.webp';
import FAF24 from '@/assets/events/FAF24.webp';
import WS25 from '@/assets/events/WS25.webp';
import Scaffold from '@/components/Scaffold';
import EventCard from '@/pages/Events/EventCard';

const Events = () : JSX.Element => {
    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Upcoming Events
                </Typography>
            )}
        >
            <Container>
                <Stack spacing={2}>
                    <EventCard
                        imageUrl={FAF24}
                        title="Future Fall Advisory Forum"
                        date="October 28 - 30, 2024"
                        location={{
                            name: 'Ocean Reef Club',
                            address: 'Key Largo, FL',
                        }}
                        url="https://www.bluehawk.coop/2024_fall_advisory/"
                    />
                    <EventCard
                        imageUrl={WS25}
                        title="Future Winter Summit"
                        date="January 14 - 16, 2025"
                        location={{
                            name: 'The Rimrock Resort',
                            address: 'Banff, AB, Canada',
                        }}
                        url="https://www.bluehawk.coop/25_winter/"
                    />
                    <EventCard
                        imageUrl={AC2025}
                        title="2023 Annual Conference"
                        date="April 30 - May 2, 2025"
                        location={{
                            name: 'Marriott Marquis Time Square',
                            address: 'New York, NY',
                        }}
                        url="https://www.bluehawk.coop/2023-annual-conference/"
                    />
                </Stack>
            </Container>
        </Scaffold>
    );
};

export default Events;
