import {formatAddress} from 'localized-address-format';
import ListItem from './ListItem';
import type {Address} from '@/types/contact';

type Props = {
    label : string;
    children : Address;
};

const AddressListItem = ({label, children} : Props) : JSX.Element => {
    const address = formatAddress({
        addressLines: children.addressLine ? [children.addressLine] : undefined,
        locality: children.city,
        postalCode: children.zipCode,
        administrativeArea: children.state,
        postalCountry: 'US',
    }).join(', ');

    const url = new URL('https://www.google.com/maps/search/?api=1');
    url.searchParams.set('query', `${address}, USA`);

    return (
        <ListItem label={label} url={url.toString()} multiline>
            {address}
        </ListItem>
    );
};

export default AddressListItem;
