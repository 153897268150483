import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToVendorPartner} from '@/mappers/vendor-partner';
import type {JsonApiDocument} from '@/types/json-api';
import type {RawVendorPartner, VendorPartner} from '@/types/vendor-partner';
import {apiUrl} from '@/utils/api';

export const useVendorPartnersQuery = () : UseQueryResult<VendorPartner[]> => {
    const fetch = useOidcFetch();

    return useQuery(['vendor-partners'], async ({signal}) => {
        const response = await fetch(apiUrl('/vendor-partners').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch vendor partners');
        }

        if (response.status === 204) {
            return null;
        }

        const raw = await response.json() as JsonApiDocument<RawVendorPartner[]>;
        return raw.data.map(mapRawToVendorPartner);
    });
};
