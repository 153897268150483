import {Avatar, Box, Card, CardContent, CardHeader, Stack} from '@mui/material';
import DividedList from './DividedList';
import {EmailListItem, PhoneListItem} from './ListItem';
import AddressListItem from './ListItem/AddressListItem';
import type {BhsContact} from '@/types/contact';

type Props = {
    contact : BhsContact;
};

const BlueHawkStaffCard = ({contact} : Props) : JSX.Element => {
    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar alt={contact.name} src={contact.photoUrl}/>
                }
                title={contact.name}
                subheader={contact.title}
            />
            <CardContent>
                <DividedList>
                    {(contact.phoneNumber || contact.faxNumber) && (
                        <Stack direction="row">
                            {contact.phoneNumber && (
                                <Box sx={{flexGrow: 1}}>
                                    <PhoneListItem label="Phone">
                                        {contact.phoneNumber}
                                    </PhoneListItem>
                                </Box>

                            )}
                            {contact.faxNumber && (
                                <Box sx={{flexGrow: 1}}>
                                    <PhoneListItem label="Fax">
                                        {contact.faxNumber}
                                    </PhoneListItem>
                                </Box>

                            )}
                        </Stack>
                    )}

                    {contact.emailAddress && (
                        <EmailListItem label="Email">{contact.emailAddress}</EmailListItem>
                    )}

                    {contact.address && (
                        <AddressListItem label="Address">{contact.address}</AddressListItem>
                    )}
                </DividedList>
            </CardContent>
        </Card>
    );
};

export default BlueHawkStaffCard;
