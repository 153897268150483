import type {ReactNode} from 'react';
import {Navigate} from 'react-router-dom';
import type {Permission} from '@/hooks/useUser';
import useUser, {hasPermission} from '@/hooks/useUser';

type Props = {
    permission : Permission;
    redirect ?: boolean;
    fallback ?: ReactNode | null;
    children : ReactNode;
};

const RequirePermission = ({permission, redirect, fallback, children} : Props) : JSX.Element | null => {
    const user = useUser();

    if (!hasPermission(user, permission)) {
        return redirect ? <Navigate to="/"/> : <>{fallback}</>;
    }

    return <>{children}</>;
};

export default RequirePermission;
