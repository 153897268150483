import UAParser from 'ua-parser-js';

export enum Platform {
    native,
    firefoxPre79,
    firefox,
    opera,
    iDevice,
    other,
}

const mobileDeviceTypes = ['mobile', 'tablet'];

const uaParser = new UAParser();
const parseResult = uaParser.getResult();
const isMobile = parseResult.device.type && mobileDeviceTypes.includes(parseResult.device.type);
const isAndroid = parseResult.os.name?.toLowerCase().includes('android');
const isIOs = parseResult.os.name?.toLowerCase().includes('ios');
const isFirefox = parseResult.browser.name?.toLowerCase().includes('firefox');
const isOpera = parseResult.browser.name?.toLowerCase().includes('opera');
const majorBrowserVersion = parseResult.browser.version ? parseInt(parseResult.browser.version, 10) : 0;

export const getPlatform = () : Platform => {
    if (Object.prototype.hasOwnProperty.call(window, 'BeforeInstallPromptEvent')) {
        return Platform.native;
    }

    if (!isMobile) {
        return Platform.other;
    }

    if (isAndroid && isFirefox) {
        return majorBrowserVersion >= 79 ? Platform.firefox : Platform.firefoxPre79;
    }

    if (isAndroid && isOpera) {
        return Platform.other;
    }

    if (isIOs) {
        return Platform.iDevice;
    }

    return Platform.other;
};
