import {Link, Typography} from '@mui/material';
import type {ReactNode} from 'react';

type Props = {
    label : string;
    url ?: string;
    children : ReactNode;
    multiline ?: boolean;
};

const ListItem = ({label, url, children, multiline} : Props) : JSX.Element => {
    return (
        <>
            <Typography component="div" variant="body2" color="grey.600">{label}</Typography>
            <Typography component="div" sx={
                multiline
                    ? {}
                    : {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }
            }>
                {url
                    ? <Link href={url} target="_blank" rel="noreferrer">{children}</Link>
                    : children
                }
            </Typography>
        </>
    );
};

export default ListItem;
