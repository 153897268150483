import {useAuth0} from '@auth0/auth0-react';
import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import {serializeError} from 'serialize-error';
import {apiUrl} from '@/utils/api';

type CreateErrorValues = {
    error : Error;
    componentStack : string;
};

export const useCreateErrorMutation = () : UseMutationResult<void, unknown, CreateErrorValues> => {
    const {getAccessTokenSilently} = useAuth0();

    return useMutation(async (values : CreateErrorValues) => {
        const {stack, ...error} = serializeError(values.error);
        let accessToken;

        try {
            accessToken = await getAccessTokenSilently();
        } catch {
            return;
        }

        const response = await fetch(apiUrl('/errors').toString(), {
            method: 'POST',
            body: JSON.stringify({
                path: window.location.pathname,
                componentStack: values.componentStack,
                error,
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            console.error('Failed to log error: ', values);
        }
    });
};
