import ListItem from './ListItem';

type Props = {
    label : string;
    children : string;
};

const EmailListItem = ({label, children} : Props) : JSX.Element => {
    return (
        <ListItem label={label} url={`mailto:${children}`}>
            {children}
        </ListItem>
    );
};

export default EmailListItem;
