import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Auth0Provider} from '@auth0/auth0-react';
import {createTheme, CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {SnackbarProvider} from 'notistack';
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
// eslint-disable-next-line import/no-unresolved
import {registerSW} from 'virtual:pwa-register';
import App from './App';
import ActAsProvider from '@/components/ActAsProvider';
import ErrorHandler from '@/components/ErrorHandler';
import OidcSecure, {AccountDisabled} from '@/components/OidcSecure';
import RequirePermission from '@/components/RequirePermission';

const theme = createTheme({
    palette: {
        primary: {
            main: '#244ca1',
        },
        background: {
            default: '#fafafa',
        },
    },
});
const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
registerSW({
    immediate: true,
});

if (!container) {
    throw new Error('Root element missing');
}

const queryClient = new QueryClient();

const root = createRoot(container);
root.render((
    <StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Auth0Provider
                domain={import.meta.env.VITE_APP_AUTH0_DOMAIN}
                clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID}
                useRefreshTokens
                cacheLocation="localstorage"
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: import.meta.env.VITE_APP_AUTH0_AUDIENCE,
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ErrorHandler>
                        <OidcSecure>
                            <RequirePermission permission="read:dashboard" fallback={<AccountDisabled/>}>
                                <SnackbarProvider maxSnack={1}>
                                    <ActAsProvider>
                                        <App/>
                                    </ActAsProvider>
                                </SnackbarProvider>
                            </RequirePermission>
                        </OidcSecure>
                    </ErrorHandler>
                </QueryClientProvider>
            </Auth0Provider>
        </ThemeProvider>
    </StrictMode>
));
