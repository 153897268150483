import {SvgIcon} from '@mui/material';

const MenuIcon = () : JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 24 24">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="
                    M14 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm0 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm-2 8a2 2 0 1 0 0-4 2 2 0 0 0 0
                    4z
                "
                fill="context-fill"
                fillOpacity=".8"
            />
        </SvgIcon>
    );
};

export default MenuIcon;
