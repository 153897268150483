import {useAuth0} from '@auth0/auth0-react';
import {Button, Card, CardActions, CardContent, CardHeader, Container, Typography} from '@mui/material';

const ErrorFallback = () : JSX.Element => {
    const {logout} = useAuth0();

    return (
        <Container sx={{my: 2}}>
            <Card variant="outlined" sx={{backgroundColor: 'grey.100'}}>
                <CardHeader title="Something went wrong"/>
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        An unexpected error occurred. We have logged it and will investigate the issue.
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => {
                        window.location.replace(window.location.origin);
                    }}>
                        Reload app
                    </Button>
                    <Button size="small" onClick={() => {
                        logout({
                            logoutParams: {
                                returnTo: window.location.origin,
                            },
                        });
                    }}>
                        Return to Login
                    </Button>
                </CardActions>
            </Card>
        </Container>
    );
};

export default ErrorFallback;
