import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import type {JsonApiDocument} from '@/types/json-api';
import type {ParentCompany} from '@/types/parent-company';
import {apiUrl} from '@/utils/api';

export const useParentCompaniesQuery = (enabled : boolean) : UseQueryResult<ParentCompany[]> => {
    const fetch = useOidcFetch();

    return useQuery(['parent-companies'], async ({signal}) => {
        const response = await fetch(apiUrl('/parent-companies').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch parent companies');
        }

        const raw = await response.json() as JsonApiDocument<ParentCompany[]>;
        return raw.data;
    }, {
        enabled,
    });
};
