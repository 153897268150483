import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Tab, Tabs} from '@mui/material';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import type {ChartType} from './Chart';
import Chart from './Chart';
import type {FinancialsVendor, YearValues} from '@/types/financials';

type Props = {
    summary : YearValues[];
    vendor : FinancialsVendor | null;
};

type Params = {
    type : string;
};

const isValidType = (type : unknown) : type is ChartType => {
    return type === 'rebate' || type === 'purchase';
};

const ChartTabs = ({vendor, summary} : Props) : JSX.Element => {
    const navigate = useNavigate();
    const params = useParams<Params>();

    if (!isValidType(params.type)) {
        return <Navigate to="/"/>;
    }

    return (
        <>
            <Tabs value={params.type} variant="fullWidth" sx={{mb: 2}} onChange={(event, value) => {
                navigate(`/financials/totals/${value as ChartType}`);
            }}>
                <Tab icon={<CurrencyExchangeIcon/>} iconPosition="start" label="Rebates" value="rebate"/>
                <Tab icon={<ShoppingCartIcon/>} iconPosition="start" label="Purchases" value="purchase"/>
            </Tabs>

            <Chart years={vendor?.years ?? summary} type={params.type}/>
        </>
    );
};

export default ChartTabs;
