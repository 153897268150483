import DashboardIcon from '@mui/icons-material/Dashboard';
import LinkIcon from '@mui/icons-material/Link';
import PeopleIcon from '@mui/icons-material/People';
import {Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography} from '@mui/material';
import {parseTemplate} from 'url-template';
import Scaffold from '@/components/Scaffold';
import useUser from '@/hooks/useUser';

const communityUrlTemplate = parseTemplate(import.meta.env.VITE_APP_COMMUNITY_URL_TEMPLATE);

const MemberResources = () : JSX.Element => {
    const user = useUser();

    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Member Resources
                </Typography>
            )}
        >
            <Container>
                <Paper>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open(communityUrlTemplate.expand({email: user.email}), 'noopener');
                            }}>
                                <ListItemIcon><PeopleIcon/></ListItemIcon>
                                <ListItemText>Online Community</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open(import.meta.env.VITE_APP_TREDENCE_DASHBOARD_URL, 'noopener');
                            }}>
                                <ListItemIcon><DashboardIcon/></ListItemIcon>
                                <ListItemText>The Dashboard</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open(
                                    'https://www.bluehawk.coop/growing-forward-member-profile-form/',
                                    'noopener'
                                );
                            }}>
                                <ListItemIcon><LinkIcon/></ListItemIcon>
                                <ListItemText>Growing Forward</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open(
                                    'https://bhsolutionpartners.savings4members.com/programs/views/green/super.html',
                                    'noopener'
                                );
                            }}>
                                <ListItemIcon><LinkIcon/></ListItemIcon>
                                <ListItemText>Savings4Members</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open('https://www.bluehawk.coop', 'noopener');
                            }}>
                                <ListItemIcon><LinkIcon/></ListItemIcon>
                                <ListItemText>www.bluehawk.coop</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {
                                window.open('https://fillitnow.com/', 'noopener');
                            }}>
                                <ListItemIcon><LinkIcon/></ListItemIcon>
                                <ListItemText>Fill It Now</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Paper>
            </Container>
        </Scaffold>
    );
};

export default MemberResources;
