import {useAuth0} from '@auth0/auth0-react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useCreateUserMetricMutation} from '@/mutations/user-metric';

const UserMetricCollector = () : null => {
    const {user} = useAuth0();
    const {pathname} = useLocation();
    const createUserMetricMutation = useCreateUserMetricMutation();

    useEffect(() => {
        createUserMetricMutation.mutate({path: pathname, userDisplayName: user?.name ?? 'Unknown'});
    }, [pathname, createUserMetricMutation.mutate]);

    return null;
};

export default UserMetricCollector;
