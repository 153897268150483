import IosShareIcon from '@mui/icons-material/IosShare';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {FirefoxA2hsIcon, MenuIcon, OperaA2hsIcon} from '@/components/PwaInstall/icons';
import {Platform} from '@/components/PwaInstall/platforms';

type Props = {
    open : boolean;
    platform : Platform;
    onInstall : () => void;
    onDismiss : () => void;
};

const InstallDialog = ({open, platform, onInstall, onDismiss} : Props) : JSX.Element => {
    return (
        <Dialog open={open} maxWidth="sm" fullWidth>
            <DialogTitle>Install Web App</DialogTitle>
            <DialogContent dividers>
                You can install this application on your device.

                {platform === Platform.iDevice && (
                    <List>
                        <ListItem disableGutters>
                            <ListItemIcon><IosShareIcon/></ListItemIcon>
                            <ListItemText>Tap the share button</ListItemText>
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText>Then find and tap 'Add to Homescreen'</ListItemText>
                        </ListItem>
                    </List>
                )}

                {platform === Platform.firefoxPre79 && (
                    <List>
                        <ListItem disableGutters>
                            <ListItemIcon><FirefoxA2hsIcon/></ListItemIcon>
                            <ListItemText>Tap this icon on the address bar</ListItemText>
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText>Then tap 'Add to Homescreen'</ListItemText>
                        </ListItem>
                    </List>
                )}

                {platform === Platform.firefox && (
                    <List>
                        <ListItem disableGutters>
                            <ListItemIcon><MenuIcon/></ListItemIcon>
                            <ListItemText>Tap the menu button</ListItemText>
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemText>Then tap 'Install'</ListItemText>
                        </ListItem>
                    </List>
                )}

                {platform === Platform.opera && (
                    <List>
                        <ListItem disableGutters>
                            <ListItemIcon><MenuIcon/></ListItemIcon>
                            <ListItemText>Tap the menu button</ListItemText>
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemIcon><OperaA2hsIcon/></ListItemIcon>
                            <ListItemText>Then tap 'Home screen'</ListItemText>
                        </ListItem>
                    </List>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss}>Dismiss</Button>
                <Button onClick={onInstall}>
                    {platform === Platform.native ? 'Install' : 'Okay'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InstallDialog;
