import {AppBar, Box, Toolbar} from '@mui/material';
import {motion} from 'framer-motion';
import type {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import HideOnScroll from '@/components/HideOnScroll';

type Props = {
    appBar : ReactNode;
    children : ReactNode;
    motionKey ?: string;
};

const Scaffold = ({appBar, children, motionKey} : Props) : JSX.Element => {
    const location = useLocation();

    return (
        <>
            <HideOnScroll>
                <AppBar color="primary" elevation={0} sx={{
                    pl: 'env(safe-area-inset-left)',
                    pt: 'env(safe-area-inset-top)',
                    pr: 'env(safe-area-inset-right)',
                }}>
                    <Toolbar>
                        {appBar}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar/>

            <Box component="main" sx={{
                pl: 'env(safe-area-inset-left)',
                pr: 'env(safe-area-inset-right)',
                pt: 2,
                mb: 9,
            }}>
                <motion.div
                    key={motionKey ?? location.pathname}
                    initial="initial"
                    animate="in"
                    variants={{
                        initial: {
                            opacity: 0,
                            position: 'relative',
                            top: '15px',
                        },
                        in: {
                            opacity: 1,
                            position: 'relative',
                            top: 0,
                        },
                    }}
                    transition={{
                        type: 'tween',
                        ease: 'linear',
                        duration: 0.15,
                    }}
                >
                    {children}
                </motion.div>
            </Box>
        </>
    );
};

export default Scaffold;
