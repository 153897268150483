import {zodResolver} from '@hookform/resolvers/zod';
import {Button} from '@mui/material';
import {RhfTextField} from 'mui-rhf-integration';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import useHandleMutation from '@/hooks/useHandleMutation';
import {useCreateSupportRequestMutation} from '@/mutations/support-request';
import {errorMap} from '@/utils/zod';

const maxMessageLength = 500;

const schema = z.object({
    message: z.string().trim().min(1).max(maxMessageLength),
});

type Values = z.infer<typeof schema>;

const SupportRequestForm = () : JSX.Element => {
    const {enqueueSnackbar} = useSnackbar();
    const handleMutation = useHandleMutation();
    const createSupportRequestMutation = useCreateSupportRequestMutation();

    const form = useForm({
        resolver: zodResolver(schema, {errorMap}),
        defaultValues: {
            message: '',
        },
    });

    const handleSubmit = async (values : Values) => {
        if ((await handleMutation(createSupportRequestMutation, values)).success) {
            enqueueSnackbar(
                'Your support request has been received',
                {variant: 'success'}
            );
        }

        form.reset();
    };

    return (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
            <RhfTextField
                control={form.control}
                label="Write here…"
                name="message"
                fullWidth
                multiline
                minRows={5}
                inputProps={{maxLength: maxMessageLength}}
                maxCharacters={maxMessageLength}
            />

            <Button type="submit" variant="contained" fullWidth sx={{mt: 2}}>
                Submit Request
            </Button>
        </form>
    );
};

export default SupportRequestForm;
