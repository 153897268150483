import {Outlet} from 'react-router-dom';
import PwaInstall from '@/components/PwaInstall';
import Navigation from '@/Layout/Navigation';

const Layout = () : JSX.Element => {
    return (
        <>
            <Outlet/>
            <Navigation/>
            <PwaInstall/>
        </>
    );
};

export default Layout;
