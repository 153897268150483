import type {SvgIcon} from '@mui/material';
import {Card, CardActionArea, CardContent, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

type Props = {
    label : string;
    Icon : typeof SvgIcon;
    path : string;
};

const GridButton = ({label, Icon, path} : Props) : JSX.Element => {
    const navigate = useNavigate();

    return (
        <Card sx={{height: 115}}>
            <CardActionArea
                sx={{height: '100%'}}
                onClick={() => {
                    navigate(path);
                }}
            >
                <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Icon fontSize="large" sx={{mb: 1}} color="primary"/>
                    <Typography variant="body2" align="center">{label}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default GridButton;
