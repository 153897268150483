import {useAuth0} from '@auth0/auth0-react';
import type {User as Auth0User} from '@auth0/auth0-react';

export enum CustomProp {
    bhxId = 'https://app.bluehawk.coop/bhxId',
    company = 'https://app.bluehawk.coop/company',
    roles = 'https://app.bluehawk.coop/roles',
}

type CustomProps = {
    [CustomProp.bhxId] : number;
    [CustomProp.company] : string;
    [CustomProp.roles] : string[];
};

export type User = Auth0User & Required<Pick<Auth0User, 'email'>> & CustomProps;

const isValidUser = (user : Auth0User) : user is User => {
    return user.email !== undefined
        && user[CustomProp.bhxId] !== undefined
        && user[CustomProp.company] !== undefined;
};

class InvalidUserError extends Error {
    public constructor(message : string, public readonly user : Auth0User) {
        super(message);
    }
}

/**
 * At the time of writing, there is no way to assign permissions directly to an ID token.
 *
 * The currently recommended workaround is to rely on roles, which then map to permissions within the UI. This is a
 * long-standing issue which Auth0 hasn't fixed until this date.
 *
 * While brittle and relying on the readable role names, this is sufficient for the moment.
 */
export type Permission = 'read:dashboard' | 'read:financials' | 'act-as';

const rolePermissions = new Map<string, Set<Permission>>([
    [
        'Super User',
        new Set(['read:dashboard', 'read:financials']),
    ],
    [
        'Dashboard Only',
        new Set(['read:dashboard']),
    ],
    [
        'BH Corporate',
        new Set(['read:dashboard', 'read:financials', 'act-as']),
    ],
]);

export const hasPermission = (user : User, permission : Permission) : boolean => {
    for (const role of user[CustomProp.roles]) {
        const permissions = rolePermissions.get(role);

        if (permissions?.has(permission)) {
            return true;
        }
    }

    return false;
};

const useUser = () : User => {
    const {user} = useAuth0();

    if (!user) {
        throw new Error('User is not signed in');
    }

    if (!isValidUser(user)) {
        throw new InvalidUserError('User is missing required properties', user);
    }

    return user;
};

export default useUser;
