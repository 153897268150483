import type {Dispatch, ReactNode, SetStateAction} from 'react';
import {createContext, useContext, useState} from 'react';
import type {ParentCompany} from '@/types/parent-company';

type ActAsProviderValues = {
    parentCompany : ParentCompany | null;
    setParentCompany : Dispatch<SetStateAction<ParentCompany | null>>;
};

const ActAsContext = createContext<ActAsProviderValues | null>(null);

type Props = {
    children ?: ReactNode;
};

const ActAsProvider = ({children} : Props) : JSX.Element => {
    const [parentCompany, setParentCompany] = useState<ParentCompany | null>(null);

    return (
        <ActAsContext.Provider value={{parentCompany, setParentCompany}}>
            {children}
        </ActAsContext.Provider>
    );
};

export const useActAs = () : ActAsProviderValues => {
    const context = useContext(ActAsContext);

    if (!context) {
        throw new Error('ActAs used outside context');
    }

    return context;
};

export default ActAsProvider;
