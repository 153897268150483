import {Alert, Autocomplete, Box, Container, LinearProgress, Stack, TextField} from '@mui/material';
import {useMemo} from 'react';
import RenderIfVisible from 'react-render-if-visible';
import {useSearchParams} from 'react-router-dom';
import BackHeader from '@/components/BackHeader';
import Scaffold from '@/components/Scaffold';
import MemberCard from '@/pages/Contacts/Cards/MemberCard';
import {memberSort} from '@/pages/Contacts/helpers/search';
import {useP2pContactsQuery} from '@/queries/contact';

const collator = new Intl.Collator('en-US', {sensitivity: 'base'});

const P2pNetworkingGroups = () : JSX.Element => {
    const contactsQuery = useP2pContactsQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const p2pGroup = searchParams.get('query');

    const p2pGroups = useMemo(() => {
        if (!contactsQuery.data) {
            return [];
        }

        const groups = [...new Set(contactsQuery.data.map(contact => contact.p2pGroup))];
        groups.sort((a, b) => collator.compare(a, b));
        return groups;
    }, [contactsQuery.data]);

    const filteredContacts = useMemo(() => {
        if (!contactsQuery.data || !p2pGroup) {
            return null;
        }

        const contacts = contactsQuery.data.filter(contact => contact.p2pGroup === p2pGroup);
        contacts.sort(memberSort);
        return contacts;
    }, [contactsQuery.data, p2pGroup]);

    return (
        <Scaffold
            appBar={(
                <BackHeader previousPath="/contacts">P2P Networking Groups</BackHeader>
            )}
        >
            <Container>
                <Autocomplete
                    renderInput={params => <TextField {...params} label="Select P2P Group…"/>}
                    loading={!contactsQuery.data}
                    disabled={contactsQuery.data === undefined}
                    options={p2pGroups}
                    value={p2pGroup}
                    disableClearable={p2pGroup !== null}
                    onChange={(event, option) => {
                        setSearchParams(searchParams => {
                            if (!option) {
                                searchParams.delete('query');
                                return searchParams;
                            }

                            searchParams.set('query', option);
                            return searchParams;
                        });
                    }}
                />

                {contactsQuery.isLoading && <LinearProgress/>}

                <Box sx={{mt: 2}}>
                    {contactsQuery.isError && (
                        <Alert severity="error">
                            Unable to load contacts.
                        </Alert>
                    )}

                    {filteredContacts && (
                        <Stack spacing={2}>
                            {filteredContacts.map(contact => (
                                <RenderIfVisible stayRendered key={contact.id}>
                                    <MemberCard contact={contact}/>
                                </RenderIfVisible>
                            ))}
                        </Stack>
                    )}
                </Box>
            </Container>
        </Scaffold>
    );
};

export default P2pNetworkingGroups;
