import type {UseMutationResult} from '@tanstack/react-query';
import {useMutation} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {apiUrl} from '@/utils/api';
import {SubmissionError} from '@/utils/errors';

type CreateSupportRequestValues = {
    message : string;
};

export const useCreateSupportRequestMutation = () : UseMutationResult<void, unknown, CreateSupportRequestValues> => {
    const fetch = useOidcFetch();

    return useMutation(async (values : CreateSupportRequestValues) => {
        const response = await fetch(apiUrl('/support-requests').toString(), {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new SubmissionError('Failed to create support request');
        }
    });
};
