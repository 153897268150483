import ClearIcon from '@mui/icons-material/Clear';
import {Alert, Container, IconButton, InputAdornment, LinearProgress, Stack, TextField} from '@mui/material';
import type {UseQueryResult} from '@tanstack/react-query';
import type {ReactNode} from 'react';
import RenderIfVisible from 'react-render-if-visible';
import BackHeader from '@/components/BackHeader';
import Scaffold from '@/components/Scaffold';
import ScrollToTop from '@/components/ScrollToTop';
import type {UseSearchResult} from '@/pages/Contacts/helpers/search';
import type {Contact} from '@/types/contact';

type Props<T extends Contact> = {
    title : string;
    contactsQuery : UseQueryResult<T[]>;
    search : UseSearchResult<T>;
    renderCard : (contact : T) => ReactNode;
};

const ContactList = <T extends Contact>({title, contactsQuery, search, renderCard} : Props<T>) : JSX.Element => {
    return (
        <Scaffold
            appBar={(
                <BackHeader previousPath="/contacts">{title}</BackHeader>
            )}
        >
            <Container>
                {contactsQuery.isLoading && (
                    <LinearProgress/>
                )}

                {contactsQuery.isError && (
                    <Alert severity="error">
                        Unable to load contacts.
                    </Alert>
                )}

                {search.contacts && (
                    <Stack spacing={2}>
                        <TextField
                            label="Search contacts…"
                            InputProps={{
                                endAdornment: search.query !== ''
                                    ? (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => {
                                                search.setQuery('');
                                            }}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                    : undefined,
                            }}
                            value={search.query}
                            onChange={event => {
                                search.setQuery(event.target.value);
                            }}
                        />

                        {search.contacts.length === 0 && (
                            <Alert severity="info">No contacts to display.</Alert>
                        )}

                        {search.contacts.map(contact => (
                            <RenderIfVisible key={contact.id} stayRendered>
                                {renderCard(contact)}
                            </RenderIfVisible>
                        ))}
                    </Stack>
                )}
            </Container>

            <ScrollToTop/>
        </Scaffold>
    );
};

export default ContactList;
