import type {PhoneNumber} from 'libphonenumber-js';
import ListItem from './ListItem';

type Props = {
    label : string;
    children : PhoneNumber;
};

const PhoneListItem = ({label, children} : Props) : JSX.Element => {
    return (
        <ListItem label={label} url={children.format('RFC3966')}>
            {children.formatNational()}
        </ListItem>
    );
};

export default PhoneListItem;
