import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LanIcon from '@mui/icons-material/Lan';
import PeopleIcon from '@mui/icons-material/People';
import {Container, Typography, Unstable_Grid2 as Grid} from '@mui/material';
import GridButton from '@/components/GridButton';
import BlueHawkIcon from '@/components/Icons/BlueHawk';
import Scaffold from '@/components/Scaffold';

const Overview = () : JSX.Element => {
    return (
        <Scaffold
            appBar={(
                <Typography variant="h6">
                    Contacts
                </Typography>
            )}
        >
            <Container>
                <Grid container spacing={2}>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/blue-hawk-staff"
                            label="BLUE HAWK Staff"
                            Icon={BlueHawkIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/vendor-partners"
                            label="Vendor Partners"
                            Icon={BlueHawkIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/member-primary-contacts"
                            label="Member Primary Contacts"
                            Icon={ContactPhoneIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/solution-and-alliance-partners"
                            label="Solution & Alliance Partners"
                            Icon={BlueHawkIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/board-of-directors"
                            label="Board of Directors"
                            Icon={PeopleIcon}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/contacts/p2p-networking-groups"
                            label="P2P Networking Groups"
                            Icon={LanIcon}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Scaffold>
    );
};

export default Overview;
