import type {UseQueryResult} from '@tanstack/react-query';
import {useQuery} from '@tanstack/react-query';
import useOidcFetch from '@/hooks/useOidcFetch';
import {mapRawToSolutionPartner} from '@/mappers/solution-partner';
import type {JsonApiDocument} from '@/types/json-api';
import type {RawSolutionPartner, SolutionPartner} from '@/types/solution-partner';
import {apiUrl} from '@/utils/api';

export const useSolutionPartnersQuery = () : UseQueryResult<SolutionPartner[]> => {
    const fetch = useOidcFetch();

    return useQuery(['solution-partners'], async ({signal}) => {
        const response = await fetch(apiUrl('/solution-partners').toString(), {
            signal,
        });

        if (!response.ok) {
            throw new Error('Unable to fetch solution partners');
        }

        if (response.status === 204) {
            return null;
        }

        const raw = await response.json() as JsonApiDocument<RawSolutionPartner[]>;
        return raw.data.map(mapRawToSolutionPartner);
    });
};
