import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import ContactsIcon from '@mui/icons-material/Contacts';
import DatasetIcon from '@mui/icons-material/Dataset';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import {Box, Container, Typography, Unstable_Grid2 as Grid} from '@mui/material';
import logoSquare from '@/assets/logo-square-white.svg';
import {useActAs} from '@/components/ActAsProvider';
import GridButton from '@/components/GridButton';
import RequirePermission from '@/components/RequirePermission';
import Scaffold from '@/components/Scaffold';
import useUser, {CustomProp} from '@/hooks/useUser';

const Dashboard = () : JSX.Element => {
    const user = useUser();
    const actAs = useActAs();

    return (
        <Scaffold
            appBar={(
                <>
                    <Box component="img" src={logoSquare} alt="BLUE HAWK" height={40} sx={{
                        mr: 2,
                    }}/>
                    <Typography variant="body2" sx={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        textAlign: 'right',
                        lineHeight: '1',
                    }}>
                        {actAs.parentCompany?.name ?? user[CustomProp.company]}
                    </Typography>
                </>
            )}
        >
            <Container>
                <Grid container spacing={2}>
                    <RequirePermission permission="read:financials">
                        <Grid xs={6}>
                            <GridButton path="/financials" label="Financial Highlights" Icon={AttachMoneyIcon}/>
                        </Grid>
                    </RequirePermission>
                    <Grid xs={6}>
                        <GridButton path="/contacts" label="Contacts" Icon={ContactsIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton path="/member-resources" label="Member Resources" Icon={DatasetIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton path="/events" label="Upcoming Events" Icon={CalendarMonthIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton path="/account" label="Account" Icon={FingerprintIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton path="/vendor-partners" label="Product Categories" Icon={CategoryIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton path="/support" label="Help" Icon={HelpOutlineIcon}/>
                    </Grid>
                    <Grid xs={6}>
                        <GridButton
                            path="/solution-partners"
                            label="Value Added Business Services"
                            Icon={MiscellaneousServicesIcon}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Scaffold>
    );
};

export default Dashboard;
