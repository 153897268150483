import PartnerCard from '@/pages/Contacts/Cards/PartnerCard';
import ContactList from '@/pages/Contacts/ContactList';
import {partnerSearchKeys, partnerSort, useSearch} from '@/pages/Contacts/helpers/search';
import {useContactsByTypeQuery} from '@/queries/contact';

const SolutionAndAlliancePartners = () : JSX.Element => {
    const contactsQuery = useContactsByTypeQuery('sap');
    const search = useSearch(contactsQuery, partnerSort, partnerSearchKeys);

    return (
        <ContactList
            title="Solution & Alliance Partners"
            contactsQuery={contactsQuery}
            search={search}
            renderCard={contact => (
                <PartnerCard contact={contact}/>
            )}
        />
    );
};

export default SolutionAndAlliancePartners;
