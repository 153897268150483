import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Card, CardActionArea, CardContent, CardHeader} from '@mui/material';
import {useState} from 'react';
import ContactDialog from '@/pages/Contacts/Cards/ContactDialog';
import type {VpSapContact} from '@/types/contact';

type Props = {
    contact : VpSapContact;
};

const PartnerCard = ({contact} : Props) : JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Card>
            <CardActionArea onClick={() => {
                setDialogOpen(true);
            }}>
                <CardHeader
                    title={contact.companyName}
                    action={<ArrowForwardIcon sx={{mt: 1, mr: 1, ml: 1}}/>}
                />
                <CardContent>
                    {contact.descriptionOfService}
                </CardContent>
            </CardActionArea>

            <ContactDialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                contact={contact}
            />
        </Card>
    );
};

export default PartnerCard;
