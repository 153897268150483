import {LocalDate} from '@js-joda/core';
import {parsePhoneNumber} from 'libphonenumber-js/min';
import type {ContactOfType, ContactType, RawContactOfType} from '@/types/contact';

export const mapRawToContact = <T extends ContactType>(raw : RawContactOfType<T>) : ContactOfType<T> => {
    const {phoneNumber, faxNumber, joinedOn, ...rest} = raw;

    return {
        ...(rest as RawContactOfType<T>),
        phoneNumber: phoneNumber ? parsePhoneNumber(phoneNumber) : undefined,
        faxNumber: faxNumber ? parsePhoneNumber(faxNumber) : undefined,
        joinedOn: joinedOn ? LocalDate.parse(joinedOn) : undefined,
    };
};
