import {Navigate, Route, Routes} from 'react-router-dom';
import BlueHawkStaff from './BlueHawkStaff';
import BoardOfDirectors from './BoardOfDirectors';
import MemberPrimaryContacts from './MemberPrimaryContacts';
import Overview from './Overview';
import P2pNetworkingGroups from './P2pNetworkingGroups';
import SolutionAndAlliancePartners from './SolutionAndAlliancePartners';
import VendorPartners from './VendorPartners';

const Contacts = () : JSX.Element => {
    return (
        <Routes>
            <Route index element={<Overview/>}/>
            <Route path="blue-hawk-staff" element={<BlueHawkStaff/>}/>
            <Route path="vendor-partners" element={<VendorPartners/>}/>
            <Route path="solution-and-alliance-partners" element={<SolutionAndAlliancePartners/>}/>
            <Route path="member-primary-contacts" element={<MemberPrimaryContacts/>}/>
            <Route path="board-of-directors" element={<BoardOfDirectors/>}/>
            <Route path="p2p-networking-groups" element={<P2pNetworkingGroups/>}/>

            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};

export default Contacts;
